#barraaceptacion{
	display:none;
	position:fixed;
	left:0px;
	right:0px;
	bottom:0.6%;
	padding:10px;
	margin: 0;
	width:100%;
	text-align:left;
	min-height:40px;	
	z-index:99999;	
	
	.barra{
		
		padding: 0.9375rem !important;
		border-radius: 4px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		background-color:rgba(0,0,0,0.85);
	
		p{
			display:block;
			float:left;
			color:#fff;
			margin: 0;
			padding: 6px 0;
			font-size: 14px;
			line-height: 1.6em;
		}
		img#ok_cookie{
               width: 64px;
               height: auto; 
			display:block;
			float:left;
		}
		a.ok{
			padding:4px;
			color:#febf27;
			text-decoration:none;
			float:left;
			
		}
		a.info{
			text-decoration:underline;
			color:#febf27;
			font-weight:bold;
		}
		a.btn-ok{
			border-radius: 4px;
			-moz-border-radius: 4px;
			-webkit-border-radius: 4px;
			background: _palette(bg);
			width: 100%;
			text-align: center;
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;
			color: #fff;	
			margin: 10px 0;
    		padding: 10px 0;
			float: right;
		}
	}
}